import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SingleLeadPost from './SingleLeadPost';
import SingleSecondLead from './SingleSecondLead';

const LeadPost = () => {
    const [news, setNews] = useState([]);
    const [secondLead, setSecondLead] = useState([]);

    useEffect(() => {
        fetch('https://unmapi.onrender.com/allposts?category=Lead')
            .then(res => res.json())
            .then(data => {
                setNews(data);
            });
    }, []);

    useEffect(() => {
        fetch('https://unmapi.onrender.com/allposts?category=SecondLead')
            .then(res => res.json())
            .then(data => {
                setSecondLead(data);
            });
    }, []);

    return (
        <Container>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7}>
                        {/* Render SingleLeadPost component with the latest news */}
                        {news.length > 0 && <SingleLeadPost key={news[0]._id} singleNews={news[0]} />}
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}>
                        {/* Render SingleSecondLead component with the latest secondLead */}
                        {secondLead.length > 0 && <SingleSecondLead key={secondLead[0]._id} singleSecondLead={secondLead[0]} />}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default LeadPost;
