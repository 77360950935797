import React from "react";
import { Grid, CardMedia, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  textAlign: "left",
  fontWeight: "300",
  fontFamily: "Cholontika",
  textColor: "text.secondary",
  "&:hover": {
    color: "text.primary",
  },
};

const SingleLeadPost = (props) => {
  const { _id, headline, excerpt, image } = props.singleNews || {};

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
            <Link style={{ textDecoration: "none" }} to={`/allposts/${_id}`}>
              <Typography
                sx={styles}
                align="left"
                variant="h4"
                color="text.secondary"
              >
                {" "}
                {headline}
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={`/allposts/${_id}`}>
              <Typography variant="body2" color="text.secondary">
                <p
                  style={{
                    textAlign: "left",
                    fontFamily: "myriadB",
                    fontSize: 16,
                  }}
                  dangerouslySetInnerHTML={{ __html: excerpt }}
                />
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <CardMedia
              component="img"
              width="100%"
              image={`data:image/png;base64,${image}`}
              alt="image"
            />
            <Typography sx={{ px: 1 }} variant="body2" color="text.secondary">
              {/* {imageCaption} */}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SingleLeadPost;
