import React from 'react';
import { Button, Card, Grid, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';



const styles = {
    textAlign: 'left',
    fontWeight: '300',
    fontFamily: 'Cholontika',
    textColor: 'text.secondary',
    '&:hover': {
        color: 'text.primary'
    }
};

const SingleSecondLead = (props) => {
    const { _id, headline, richText, excerpt, image } = props.singleSecondLead || {}

    const body = richText;
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        <Link style={{ textDecoration: 'none' }} to={`/allposts/${_id}`}>
                            <Typography sx={styles} align="left" variant="h4" color="text.secondary"> {headline}
                            </Typography>
                        </Link>



                        <Typography variant="h6" color="text.secondary">
                            {/* Regular Price: {regularPrice} */}
                        </Typography>
                        <Typography variant="h6" color="red">
                            {/* Discount Price: {price} */}
                        </Typography>

                        <Link style={{ textDecoration: 'none' }} to={`/allposts/${_id}`}>
                            <Typography variant="body2" color="text.secondary">
                                <p style={{ textAlign: 'left', fontFamily: 'myriadB', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: excerpt }} />
                            </Typography>
                        </Link>

                        {/* <p><small>Only {stock} left in stock - Order Now</small></p> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>



                        </div>



                    </Grid>

                </Grid>
            </Box>






        </>
    );
};

export default SingleSecondLead;