import React, { useState } from "react";
import { Button, Container, Input, MenuItem, TextField } from "@mui/material";
import Header from "../../Home/Navigation/Header";
import MyEditor from "../MyEditor";

const categories = [
  {
    value: "Lead",
    label: "Lead",
  },
  {
    value: "Banner",
    label: "Banner",
  },
  {
    value: "General",
    label: "General",
  },
  {
    value: "Politics",
    label: "Politics",
  },

  {
    value: "National",
    label: "cricket",
  },

  {
    value: "athletics",
    label: "athletics",
  },

  {
    value: "football",
    label: "football",
  },

  {
    value: "Sports",
    label: "Sports",
  },

  {
    value: "Economy",
    label: "Economy",
  },

  {
    value: "Health",
    label: "Health",
  },

  {
    value: "Lifestyle",
    label: "Lifestyle",
  },

  {
    value: "Relations",
    label: "Relations",
  },

  {
    value: "Education",
    label: "Education",
  },

  {
    value: "Entertainment",
    label: "Entertainment",
  },

  {
    value: "Tech",
    label: "Tech",
  },

  {
    value: "SecondLead",
    label: "SecondLead",
  },
];

const AddPost = () => {
  const [bannerHead, setBannerHead] = useState("");
  const [headline, setHeadLine] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [image, setImage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [richText, setRichText] = useState(""); // State to hold editor content
  const [category, setCategory] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image || !richText) {
      return;
    }

    const formData = new FormData();
    formData.append("richText", richText); // Include richText in form data
    formData.append("headline", headline);
    formData.append("bannerHead", bannerHead);
    formData.append("excerpt", excerpt);
    formData.append("category", category);
    formData.append("image", image);

    fetch("https://unmapi.onrender.com/allposts", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.insertedId) {
          setSuccess("Post added successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Container>
      <Header />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Cholontika",
          fontWeight: 400,
        }}
      >
        নতুন পোস্ট লিখুন
      </h1>

      <form onSubmit={handleSubmit}>
        <TextField
          sx={{ width: "100%", m: 1 }}
          label="ব্যানারের শিরোনাম - শুধুমাত্র ব্যানার সংবাদের ক্ষেত্রে প্রযোজ্য"
          name="bannerHead"
          onChange={(e) => setBannerHead(e.target.value)}
          variant="standard"
        />

        <TextField
          sx={{ width: "100%", m: 1 }}
          label="সংবাদের শিরোনাম"
          name="headline"
          onChange={(e) => setHeadLine(e.target.value)}
          required
          variant="standard"
        />

        <MyEditor
          value={richText} // Pass richText as value prop
          onChange={setRichText} // Pass setRichText as onChange prop
        />

        <TextField
          sx={{ width: "100%", m: 1 }}
          label="সারাংশ"
          name="excerpt"
          onChange={(e) => setExcerpt(e.target.value)}
          variant="standard"
        />

        <Input
          sx={{ width: "100%", m: 1 }}
          accept="image/*"
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
        />

        <TextField
          sx={{ width: "100%", m: 1 }}
          select
          value={category}
          label="ক্যাটাগরি"
          onChange={(e) => setCategory(e.target.value)}
          helperText="Please Select Category"
          variant="standard"
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </TextField>
        <Button sx={{ width: "75%", m: 1 }} variant="contained" type="submit">
          Add Post
        </Button>
      </form>

      {success && <p style={{ color: "green" }}> {success}</p>}
    </Container>
  );
};

export default AddPost;
