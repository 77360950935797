import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';

export default function Footer() {
    const [value, setValue] = React.useState(0);

    return (
        <Container>
            <Box >
                <hr />
                <Grid item xs={12} sm={12} md={6}>

                    <Typography sx={{ fontFamily: 'Noirrit', pb: 2 }} variant='body'>
                        ওয়েবসাইট নির্মাণাধীন
                        <br />
                        সম্পাদক: সোহেইল জাফর | খেলা মিডিয়া লিমিটেড কর্তৃক
                        <br />
                        ৭৬ বীরউত্তম কাজী নূরুজ্জামান সড়ক, ঢাকা থেকে প্রকাশিত | দূরালাপনী: +৮৮০১৯৮৮৮৩৩৩৭১
                    </Typography>

                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <Link to={'/'} style={{ textDecoration: "none" }}> <BottomNavigationAction label="Home" icon={<HomeIcon />} />Home</Link>
                        <BottomNavigationAction label="About Us" />
                        <Link to={'/privacy-policy'} style={{ textDecoration: "none" }}> <BottomNavigationAction label="Privacy Policy" icon={<VerifiedUserIcon />} />Privacy Policy</Link>
                        <BottomNavigationAction label="Terms of Use" />
                        <BottomNavigationAction label="Khela Team" />
                    </BottomNavigation>
                </Grid>
            </Box>
        </Container>
    );
}
