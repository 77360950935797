import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import SingleBanner from './SingleBanner';

const TopBanner = () => {
    const [posts, setPosts] = useState(null); // Initialize posts state as null

    useEffect(() => {
        fetch('https://unmapi.onrender.com/allposts?category=Lead')
            .then(res => res.json())
            .then(data => {
                setPosts(data); // Set the latest item as the value of posts state
            });
    }, []);

    // ...
    return (
        <>
            {posts === null ? (
                <CircularProgress />
            ) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            {/* Make sure to check the data structure before passing to SingleBanner */}
                            <SingleBanner key={posts._id} post={posts[0]}></SingleBanner>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );

};

export default TopBanner;
