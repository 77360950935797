import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
    textAlign: 'left',
    fontWeight: '700',
    fontFamily: 'Noirrit',
    color: 'error.main',
    textColor: 'text.secondary',
    '&:hover': {
        color: 'text.primary'
    }
};

const CategoryList = ({ kategory }) => {
    // const { _id, headline, richText, excerpt, image } = props.kategory || {};

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <ul>
                        <li style={styles}>

                            <Link style={{ textDecoration: 'none' }} to={`/allposts/${kategory._id}`}> {kategory.headline}</Link>

                        </li>
                    </ul>


                </Grid>


            </Grid>

        </>
    );
};

export default CategoryList;