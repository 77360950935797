import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Home/Navigation/Footer";
import Header from "../Home/Navigation/Header";

const AllNewspaper = () => {
  return (
    <Container>
      <Header></Header>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} columns={16}>
          <Grid item xs={4}>
            <Link to="//bonikbarta.net/">
              <Typography variant="body1"> বণিকবার্তা </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//bangla.bdnews24.com/">
              <Typography variant="body1"> বিডিনিউজ২৪ </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//www.deshrupantor.com/">
              <Typography variant="body1"> দেশ রূপান্তর </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//samakal.com/">
              <Typography variant="body1"> সমকাল </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="https://epaper.bonikbarta.net/">
              <Typography variant="body1"> বণিকবার্তা ই-পেপার </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//www.deshrupantor.com/epaper/">
              <Typography variant="body1"> দেশ রূপান্তর ই-পেপার </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//epaper.samakal.com/">
              <Typography variant="body1"> সমকাল ই-পেপার </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="//bonikbarta.net/issues/1/%E0%A6%B8%E0%A6%BF%E0%A6%B2%E0%A7%8D%E0%A6%95%E0%A6%B0%E0%A7%81%E0%A6%9F">
              <Typography variant="body1"> বণিকবার্তা সিল্করুট </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Footer></Footer>
    </Container>
  );
};

export default AllNewspaper;
