import { Box, CircularProgress, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../../Navigation/Header';
import Post from '../../Post/Post';




const Sports = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch('https://unmapi.onrender.com/allposts?category=Sports')
            .then(res => res.json())
            .then(data => setPosts(data))
    }, [])


    return (

        <Container>
            <Header></Header>
            {posts.length === 0 ?
                <CircularProgress />
                :
                < Box sx={{ flexGrow: 1 }}>

                    <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {
                            posts.map(post =>
                                <Grid item xs={12} sm={6} md={4}>

                                    <Post
                                        key={post._id}
                                        post={post}
                                    >
                                    </Post>



                                </Grid>)
                        }

                    </Grid>
                </Box>
            }


        </Container>



    );
};

export default Sports;



