import { Box, CircularProgress, Container, Typography } from '@mui/material';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Privacy = () => {
    return (
        <Container>
            <Header></Header>
            <Typography align='left'>
                <p> Welcome to Khela TV ("we," "our," or "us"). This Privacy Policy outlines the practices we follow concerning the collection, use, and sharing of information when you visit our sports-based online portal, Khela TV (the "Portal", "khelatv.com"). This policy also explains your choices regarding the information we collect and how you can exercise your rights.</p>

                <h3> Information We Collect</h3>

                <p> 1.1. Personal Information: When you visit Khela TV, we may collect certain personally identifiable information ("Personal Information") that you voluntarily provide to us, such as your name, email address, age, location, and other details you submit through forms or other interactive features on our Portal.</p>

                <p>1.2. Usage Data: We may collect non-personally identifiable information, including but not limited to your IP address, device information, browser type, operating system, and other technical details through cookies and similar technologies to analyze and improve the performance and functionality of our Portal.
                </p>
                <p> 1.3. User Contributions: If you interact with Khela TV by leaving comments, participating in forums, or contributing content, we may collect the information you share in such interactions.
                </p>
                <h3> How We Use Your Information
                </h3>
                <p> 2.1. Provide Services: We use your Personal Information to deliver and enhance the services available on Khela TV, including personalized content, sports news, and other features.</p>

                <p> 2.2. Improve User Experience: We may analyze usage patterns and behavior to understand user preferences and optimize our Portal's performance, content, and design.
                </p>
                <p>2.3. Communication: We may use your email address to send you updates, newsletters, promotional offers, and other communications related to Khela TV. You may opt-out of these communications at any time.</p>

                <p> 2.4. Legal Obligations: We may use or disclose your information to comply with legal obligations, respond to government requests, or protect our rights and safety.</p>

                <h3>  How We Share Your Information</h3>

                <p> 3.1. Third-Party Service Providers: We may share your information with third-party service providers who assist us in delivering the services and maintaining the Portal. These providers are bound by strict confidentiality agreements and are not allowed to use your data for other purposes.</p>

                <p> 3.2. Business Transfers: In the event of a merger, acquisition, or sale of our assets, your information may be transferred to the new entity as part of the transaction.</p>

                <p>  3.3. Legal Requirements: We may disclose your information to comply with legal obligations, enforce our terms of use, or protect the rights, safety, or property of Khela TV, our users, or others.</p>

                <h3> Your Rights and Choices</h3>

                <p>4.1. Access and Correction: You have the right to access and correct your Personal Information. If you need to update or modify your information, please contact us at khelatv.com@gmail.com</p>

                <p> 4.2. Opt-Out: You can opt-out of receiving promotional emails from us by following the unsubscribe instructions provided in the email.</p>

                <p>4.3. Do Not Track Signals: We do not respond to "Do Not Track" signals or similar mechanisms.</p>

                <h3>Security</h3>

                <p>We employ industry-standard security measures to protect your information from unauthorized access, misuse, alteration, or disclosure. However, no data transmission over the internet or electronic storage can be guaranteed to be 100% secure.</p>

                <h3>Children's Privacy</h3>

                <p>Khela TV is not intended for children under the age of 18. We do not knowingly collect Personal Information from children under 18. If you believe that a child has provided us with their information, please contact us immediately, and we will promptly delete such data.
                </p>
                <h3>            Changes to Privacy Policy
                </h3>

                <p>We may update this Privacy Policy from time to time. The updated version will be posted on our Portal, and the "Effective Date" will reflect the latest revision.</p>

                <h3> Contact Us</h3>
                <p>
                    If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at khelatv.com@gmail.com</p>

                <p> By using Khela TV, you consent to the terms of this Privacy Policy. Please read this policy carefully and regularly for any updates.</p>
            </Typography>

            <Footer></Footer>
        </Container >

    );
};

export default Privacy;