import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Post from "../Post/Post";

const World = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("https://unmapi.onrender.com/allposts?category=international")
      .then((res) => res.json())
      .then((data) => setPosts(data));
  }, []);

  return (
    <Container>
      <Link style={{ textDecoration: "none" }} to={`/football`}>
        <Typography
          style={{ textAlign: "left", fontFamily: "Cholontika", fontSize: 26 }}
          sx={{ mt: 2 }}
          align="left"
          variant="h6"
          color="error.main"
        >
          {" "}
          ফুটবল{" "}
        </Typography>
      </Link>

      {posts.length === 0 ? (
        <CircularProgress />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {posts.map((post) => (
              <Grid item xs={12} sm={6} md={4}>
                <Post key={post._id} post={post}></Post>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default World;
