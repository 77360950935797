import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const MyEditor = ({ value, onChange }) => {
  const classes = useStyles();

  const handleChange = (html) => {
    onChange(html); // Call the onChange prop with the new editor content
  };

  return (
    <Paper className={classes.root} elevation={3}>
      <ReactQuill
        theme="snow"
        value={value} // Use the value prop as the editor content
        onChange={handleChange}
      />
    </Paper>
  );
};

export default MyEditor;
