import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  textAlign: "left",
  fontWeight: "300",
  fontFamily: "Cholontika",
  textColor: "text.secondary",
  "&:hover": {
    color: "text.primary",
  },
};

const Post = (props) => {
  const { _id, headline, excerpt, image, createdAt } = props.post || {};

  const postTime = createdAt;
  function formatDate(postTime) {
    const date = postTime.toLocaleString("bn-BD", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return `${date}`;
  }

  const createdAtDate = new Date(postTime);
  const formattedDate = formatDate(createdAtDate);

  return (
    <>
      <Card>
        <Link style={{ textDecoration: "none" }} to={`/allposts/${_id}`}>
          <CardMedia
            component="img"
            height="250rem"
            image={`data:image/png;base64,${image}`}
            alt="image"
          />

          <CardContent>
            <Typography
              sx={styles}
              align="left"
              variant="h5"
              color="text.secondary"
            >
              {headline.length > 50 ? headline.slice(0, 50) + "..." : headline}
            </Typography>
            <Stack direction="row" spacing={1} my={2}>
              <Typography variant="body2" color="error.main">
                নিজস্ব প্রতিবেদক
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formattedDate}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              <p
                style={{
                  textAlign: "left",
                  fontFamily: "myriadB",
                  fontSize: 16,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    excerpt.length > 85
                      ? excerpt.slice(0, 85) + "..."
                      : excerpt,
                }}
              />
            </Typography>

            {/* <Link to={`/allposts?category=${category}`}> {category}</Link> */}

            <Link to={`/allposts/${_id}`}>
              <Button variant="text" style={{ color: "red" }}>
                বিস্তারিত
              </Button>
            </Link>
          </CardContent>
        </Link>
      </Card>
    </>
  );
};

export default Post;
