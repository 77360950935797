import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const SingleBanner = (props) => {
    const { _id, bannerHead } = props.post || {};

    const styles = {
        py: 2,
        textAlign: 'center',
        fontWeight: '300',
        fontFamily: 'Cholontika',
        textColor: 'error.main',
        '&:hover': {
            color: 'text.secondary',
        },
    };

    return (
        <div>
            <Link style={{ textDecoration: 'none' }} to={`/allposts/${_id}`}>
                <Typography sx={styles} align="left" variant="h2" color="error.main">
                    {bannerHead}
                </Typography>
            </Link>
        </div>
    );
};

export default SingleBanner;
