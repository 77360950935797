import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../Navigation/Footer";
import Header from "../Navigation/Header";
import CategoryList from "./CategoryList";
import useAuth from "../../../hooks/useAuth";

const SinglePost = () => {
  const { newsId } = useParams();
  const [news, setNews] = useState([]);
  const [category, setCategory] = useState([]);
  const body = news.richText;
  const postTime = news.createdAt;
  const { admin } = useAuth();

  function formatDate(postTime) {
    // Format the date and time separately
    const date = postTime.toLocaleString("bn-BD", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const time = postTime.toLocaleString("bn-BD", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    // Concatenate the date and time strings
    return `${date}, ${time}`;
  }

  const createdAtDate = new Date(postTime);
  const formattedDate = formatDate(createdAtDate);

  useEffect(() => {
    fetch(`https://unmapi.onrender.com/allposts/${newsId}`)
      .then((res) => res.json())
      .then((data) => setNews(data));
  }, [newsId]);

  useEffect(() => {
    fetch(`https://unmapi.onrender.com/allposts?category=${news.category}`)
      .then((res) => res.json())
      .then((data) => setCategory(data));
  }, [news.category]);

  return (
    <Container>
      <Header></Header>
      <Box sx={{ m: 2 }}></Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              sx={{
                mx: 2,
                p: 2,
                fontWeight: "700",
                fontFamily: "Noirrit",
                color: "error.main",
              }}
              variant="h3"
            >
              {news.headline}
            </Typography>
            <img
              style={{ width: "100%" }}
              src={`data:image/png;base64,${news.image}`}
              alt=""
            />

            <Stack
              direction="row"
              spacing={1}
              my={2}
              style={{ textAlign: "left", fontFamily: "myriadB", fontSize: 24 }}
            >
              <Typography variant="body2" color="error.main">
                নিজস্ব প্রতিবেদক
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formattedDate}
              </Typography>
            </Stack>

            <p
              style={{ textAlign: "left", fontFamily: "myriadB", fontSize: 24 }}
              dangerouslySetInnerHTML={{ __html: body }}
            />

            {admin && (
              <Link to={`/dashboard/updatePost`}>
                <Button color="inherit">Update Post</Button>
              </Link>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Typography
              sx={{
                ml: 4,
                fontWeight: "400",
                textAlign: "left",
                fontFamily: "Cholontika",
                color: "error.main",
              }}
              variant="h5"
            >
              আরো পড়ুন{" "}
            </Typography>

            {category.map((kategory) => (
              <CategoryList
                key={kategory._id}
                kategory={kategory}
              ></CategoryList>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Footer></Footer>
    </Container>
  );
};

export default SinglePost;
