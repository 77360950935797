import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AuthProvider from "./contexts/AuthProvider/AuthProvider";
import AllNewspaper from "./Pages/AllNewspaper/AllNewspaper";
import AddPost from "./Pages/Dashboard/AddPost/AddPost";
import Dashboard from "./Pages/Dashboard/Dashboard/Dashboard";
import MakeAdmin from "./Pages/Dashboard/MakeAdmin/MakeAdmin";
import AllPost from "./Pages/Home/AllPost/AllPost";
import Sports from "./Pages/Home/AllPost/Sports/Sports";
import Home from "./Pages/Home/Home/Home";
import SinglePost from "./Pages/Home/SinglePost/SinglePost";
import AdminRoute from "./Pages/Login/AdminRoute/AdminRoute";
import Login from "./Pages/Login/Login/Login";
import Register from "./Pages/Login/Register/Register";
import Cricket from "./Pages/Home/Home/Cricket/Cricket";
import Privacy from "./Pages/Home/Navigation/Privacy";
import UpdatePost from "./Pages/Dashboard/UpdatePost/UpdatePost";
import Athletics from "./Pages/Home/Athletics/Athletics";
import Football from "./Pages/Home/Football/Football";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/football" element={<Football />} />
            <Route path="/cricket" element={<Cricket />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/athletics" element={<Athletics />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path={`/dashboard/makeAdmin`}
              element={
                <AdminRoute>
                  <MakeAdmin></MakeAdmin>
                </AdminRoute>
              }
            ></Route>
            <Route
              path={`/dashboard/addPost`}
              element={
                <AdminRoute>
                  <AddPost></AddPost>
                </AdminRoute>
              }
            ></Route>
            <Route
              path={`/dashboard/updatedPost`}
              element={
                <AdminRoute>
                  <UpdatePost></UpdatePost>
                </AdminRoute>
              }
            ></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/allposts" element={<AllPost />}></Route>
            <Route path="/allposts/:newsId" element={<SinglePost />}></Route>
            <Route path="/allnewspaper" element={<AllNewspaper />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
