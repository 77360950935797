import { Container } from "@mui/material";
import React from "react";
import Bangladesh from "../Bangladesh/Bangladesh";
import General from "../General/General";
import LeadPost from "../LeadPost/LeadPost";
import Footer from "../Navigation/Footer";
import Header from "../Navigation/Header";
import TopBanner from "../TopBanner/TopBanner";
import World from "../World/World";
import Khela from "./Khela/Khela";

const Home = () => {
  return (
    <Container sx={{ maxWidth: "md" }}>
      <Header></Header>
      <TopBanner></TopBanner>
      <LeadPost></LeadPost>
      <General></General>
      <Bangladesh></Bangladesh>
      <World></World>
      <Khela></Khela>
      <Footer></Footer>
    </Container>
  );
};

export default Home;
