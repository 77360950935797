import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList"; // Import MenuList
import logo from "../../../../src/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import HomeIcon from "@mui/icons-material/Home";

const Header = () => {
  const { user, logout } = useAuth() || {};

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar style={{ background: "#E4E8E8" }} position="static" sx={{ mb: 2 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Link to={"/"}>
              <img style={{ height: "50px" }} src={logo} alt="উন্মোচন" />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* Wrap the menu items inside the MenuList */}
              <MenuList>
                <MenuItem>
                  {/* <Link style={{ textDecoration: 'none', textAlign: "center", color: 'black' }} to="/">
                                        <Button variant="text">Home</Button>
                                    </Link> */}

                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    {" "}
                    {<HomeIcon />} খেলা টিভি
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{
                      textDecoration: "none",
                      textAlign: "left",
                      color: "black",
                    }}
                    to="/allposts?category=National"
                  >
                    <Button variant="text">Others In Action</Button>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img style={{ height: "50px" }} src={logo} alt="" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link style={{ textDecoration: "none" }} to="/athletics">
              <Button variant="outlined" sx={{ color: "black", mx: 1 }}>
                এথলেটিক্স
              </Button>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                textAlign: "center",
                color: "black",
              }}
              to="/cricket"
            >
              <Button variant="outlined" sx={{ color: "black", mx: 1 }}>
                ক্রিকেট
              </Button>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                textAlign: "center",
                color: "black",
              }}
              to="/football"
            >
              <Button variant="outlined" sx={{ color: "black", mx: 1 }}>
                ফুটবল
              </Button>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                textAlign: "center",
                color: "black",
              }}
              to="/sports"
            >
              <Button variant="outlined" sx={{ color: "black", mx: 1 }}>
                খেলা
              </Button>
            </Link>

            {user?.email ? (
              <Box>
                <Link style={{ textDecoration: "none" }} to="/allnewspaper">
                  <Button variant="outlined" sx={{ color: "black", mx: 1 }}>
                    All Bangla Newspaper
                  </Button>
                </Link>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                  color: "black",
                }}
                to="/"
              >
                <Button variant="contained">ওয়েবসাইট নির্মাণাধীন</Button>
              </Link>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Unmochon" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                {user?.email ? (
                  <Box>
                    <NavLink style={{ textDecoration: "none" }} to="/dashboard">
                      <Button color="inherit">Dashboard</Button>
                    </NavLink>
                    <Button onClick={logout} color="inherit">
                      Logout
                    </Button>
                  </Box>
                ) : (
                  <NavLink style={{ textDecoration: "none" }} to="/login">
                    <Button color="inherit">Login</Button>
                  </NavLink>
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
